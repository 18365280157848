<template>
  <div class="">
    <div class="pagenamPT">
     <h3 class="pagename2">
        {{$t('front.message.allMsg')}}<span class="blc">{{pageInfo.tatal_list_count}}</span>{{$t('front.message.num')}}
     </h3>
    </div>

    <board-filter  @onSearch="onSearch"/>

    <div class="strTablescr">
     <div class="strTablePC">
        <table class="strTablePT">
          <colgroup>
            <col width="10%">
            <col width="10%">
            <col width="50%">
            <col width="20%">
            <col width="10%">
          </colgroup>
          <thead>
            <tr>
              <th>{{$t('front.board.checks')}}</th>
              <th>{{$t('front.board.check')}}</th>
              <th>{{$t('front.board.title')}}</th>
              <th>{{$t('front.board.sendTime')}}</th>
              <th>{{$t('front.board.delete')}}</th>
            </tr>
          </thead>
          <tbody>
            <template v-if="list && list.length > 0">
              <template v-for="item in list" :key="item.msgIdx">
                <tr>
                  <td><input type="checkbox" v-model="item.checked"></td>
                  <td><span class="ocmsg" :class="{'closemsg': item.msgStatus === 'N', 'openmsg': item.msgStatus !== 'N'}"></span></td>
                  <td>
                     <a @click="onMessageClick(item)">
                      <span class="nicon iblbg mr10">{{$t('front.boardCategory.notice')}}</span>
                      {{item.msgTitle}}
                      <span class="newicon ml10">N</span>
                    </a>
                  </td>
                  <td>{{dateFormat(item.sendDate)}}</td>
                  <td><span class="nbicon bgrbg" @click="onRemoveOne(item)">{{$t('front.board.delete')}}</span></td>
                </tr>
              </template>
            </template>
            <template v-else>
              <tr>
              </tr>
            </template>
          </tbody>
        </table>
     </div>
     <div class="strTableM">
        <div class="strTablePTM">
           <template v-if="list && list.length > 0">
             <template v-for="item in list" :key="item.msgIdx">
               <ul>
                 <li>
                    <em>{{$t('front.board.checks')}}</em>
                    <input type="checkbox" v-model="item.checked">
                 </li>
                 <li>
                    <em>{{$t('front.board.check')}}</em>
                    <span class="ocmsg" :class="{'closemsg': item.msgStatus === 'N', 'openmsg': item.msgStatus !== 'N'}"></span>
                 </li>
                 <li>
                    <em>{{$t('front.board.title')}}</em>
                    <a @click="onMessageClick(item)">
                      <span class="nicon iblbg mr10">{{$t('front.boardCategory.notice')}}</span>
                      {{item.msgTitle}}
                      <span class="newicon rdbg ml10">N</span>
                    </a>
                 </li>
                 <li>
                    <em>{{$t('front.board.sendTime')}}</em>
                    {{dateFormat(item.sendDate)}}
                 </li>
                 <li>
                    <em>{{$t('front.board.delete')}}</em>
                    <span class="nbicon rdbg" @click="onRemoveOne(item)">{{$t('front.board.delete')}}</span>
                 </li>
               </ul>
             </template>
           </template>
           <template v-else>
             <ul>
                <li>{{$t('front.common.notFoundList')}}</li>
             </ul>
           </template>
        </div>
     </div>
  </div>

    <div class="applibtns">
      <a @click="onAllCheck">{{ $t('front.board.AllSelect') }}</a>
      <a @click="onRemoveMsg">{{ $t('front.board.AllDelete') }}</a>
      <a @click="onAllRead">{{ $t('front.board.AllRead') }}</a>
    </div>

    <pagination
        :pageNum="pageInfo.page"
        :pageSize="pageInfo.count_per_list"
        :totalCount="pageInfo.tatal_list_count"
        @goToPage="loadList"
    />

  </div>
</template>

<script>
import Pagination from '@/components/ui/Pagination'
import BoardFilter from '@/components/common/BoardFilter'
import { getMsgList, setMsgRead, setMsgUpdate } from '@/api/board'
export default {
  name: 'msg',
  components: { BoardFilter, Pagination },
  data () {
    return {
      list: [],
      searchParam: {
        searchOption: '',
        searchValue: ''
      },
      idxList: []
    }
  },
  created () {
    this.loadList()
  },
  methods: {
    onSearch (value) {
      this.searchParam.searchOption = value.type
      this.searchParam.searchValue = value.text
      this.loadList()
    },
    loadList (page) {
      const params = {
        page: page || this.pageInfo.page
      }
      const searchValue = this.searchParam.searchValue
      if (searchValue) {
        params.searchOption = this.searchParam.searchOption
        params.searchValue = this.searchParam.searchValue
      }
      getMsgList(params).then(response => {
        const result = response.data

        if (result.resultCode === '0') {
          const list = result.data.list
          if (result.data.pageInfo) {
            this.pageInfo = result.data.pageInfo
          }

          for (let i = 0, iLen = list.length; i < iLen; i++) {
            const item = list[i]
            item.checked = false
          }
          this.list = list
        }
      })
    },
    onMessageClick (item, isMobile) {
      item.searchParam = JSON.stringify(this.searchParam)
      this.goPageByName('msgread', item)
    },
    onAllCheck (list) {
      this.allChecked = !this.allChecked
      this.list.forEach(item => {
        item.checked = this.allChecked
      })
    },
    async onRemoveOne (item) {
      const confirm = await this.onConfirm('front.message.confirmDeleteMessageMultiple')
      if (confirm) {
        const params = {
          msgIdx: item.msgIdx,
          recieveId: item.recieveId,
          status: 'N'
        }
        await setMsgUpdate(params).then(response => {
          this.onCheck('front.message.completeDeleteMessage')
          this.loadList()
        })
      }
    },
    async onRemoveMsg () {
      let checkedCount = 0
      let unreadCount = 0
      this.list.forEach(item => {
        if (item.checked) {
          checkedCount++

          if (item.msgStatus === 'N') {
            unreadCount++
          }
        }
      })

      if (unreadCount > 0) {
        this.onCheck('front.message.cannotDeleteMessage')
        return false
      }

      if (checkedCount <= 0) {
        this.onCheck('front.message.noSelectMessage')
        return false
      }
      const confirm = await this.onConfirm('front.message.confirmDeleteMessage')

      if (confirm) {
        for (let i = 0, iLen = this.list.length; i < iLen; i++) {
          const item = this.list[i]
          if (item.checked) {
            const params = {
              msgIdx: item.msgIdx,
              recieveId: item.recieveId
            }
            setMsgUpdate(params).then(response => {
            })
          }
        }
        await this.onCheck('front.message.completeDeleteMessage')
        this.loadList()
      }
    },
    async onAllRead () {
      const confirm = await this.onConfirm('front.message.confirmAllRead')

      if (confirm) {
        const params = {
        }
        setMsgRead(params).then(response => {
          const result = response.data
          if (result.resultCode === '0') {
            this.loadList()
          }
        })
      }
    }
  }
}
</script>

<style src="@/styles/striNew.css"></style>
